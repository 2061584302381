exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-list-html-index-js": () => import("./../../../src/pages/docs/list.html/index.js" /* webpackChunkName: "component---src-pages-docs-list-html-index-js" */),
  "component---src-pages-faqs-mdx": () => import("./../../../src/pages/faqs.mdx" /* webpackChunkName: "component---src-pages-faqs-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rpsl-guide-index-js": () => import("./../../../src/pages/rpsl-guide/index.js" /* webpackChunkName: "component---src-pages-rpsl-guide-index-js" */),
  "component---src-pages-rpsl-guide-rfc-1786-mdx": () => import("./../../../src/pages/rpsl-guide/rfc1786.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-1786-mdx" */),
  "component---src-pages-rpsl-guide-rfc-2622-mdx": () => import("./../../../src/pages/rpsl-guide/rfc2622.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-2622-mdx" */),
  "component---src-pages-rpsl-guide-rfc-2650-mdx": () => import("./../../../src/pages/rpsl-guide/rfc2650.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-2650-mdx" */),
  "component---src-pages-rpsl-guide-rfc-2725-mdx": () => import("./../../../src/pages/rpsl-guide/rfc2725.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-2725-mdx" */),
  "component---src-pages-rpsl-guide-rfc-2726-mdx": () => import("./../../../src/pages/rpsl-guide/rfc2726.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-2726-mdx" */),
  "component---src-pages-rpsl-guide-rfc-2769-mdx": () => import("./../../../src/pages/rpsl-guide/rfc2769.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-2769-mdx" */),
  "component---src-pages-rpsl-guide-rfc-4012-mdx": () => import("./../../../src/pages/rpsl-guide/rfc4012.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-rfc-4012-mdx" */),
  "component---src-pages-rpsl-guide-ripe-181-mdx": () => import("./../../../src/pages/rpsl-guide/ripe-181.mdx" /* webpackChunkName: "component---src-pages-rpsl-guide-ripe-181-mdx" */)
}

